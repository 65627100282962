<template>
    <div>
       <el-checkbox v-model="checked" @change="selectMood" :disabled="disabled">显示新广告的现有心情，评论和分享</el-checkbox>
       <p class="tips" v-if="checked">勾选之后，广告将不能编辑</p>
    </div>
</template>
<script>
    export default {
        props:['value','disabled'],
        data() {
            return {
                checked:this.value
            }
        },
        methods: {
            selectMood(v){
                this.$emit('input',v)
                // if(v){
                //     this.$message({
                //         type:'warning',
                //         message:'勾选之后，广告将不能编辑'
                //     })
                // }
            }
        },
    }
</script>
<style lang="scss">
.tips{
    color: #F56C6C;
    font-size: 12px;
}
</style>